// Google Analytics
function recordAnalytics() {
  if (window.ga) {
    window.ga('set', 'page', window.location.pathname);
    window.ga('send', 'pageview');
  }
}

// Fired on a new pjax page load
function main() {
  recordAnalytics();
}
