// Init Tiny Sliders
//
// uses:
// - https://github.com/ganlanyuan/tiny-slider
//
(function (window) {
  function initSliders() {
    var sliders = document.querySelectorAll('.slider');
    for (var i = 0; i < sliders.length; i++) {
      tns({
        container: sliders[i],
        mouseDrag: true,
        gutter: 20,
        autoHeight: true,
        nav: false,
        // navPosition: 'bottom',
        controlsText: ['Previous', 'Next'],
      });
    }

    var carousels = document.querySelectorAll('.carousel');
    for (var i = 0; i < carousels.length; i++) {
      var carousel = tns({
        container: carousels[i],
        mouseDrag: true,
        autoHeight: false,
        nav: true,
        navPosition: 'bottom',
        controlsText: ['Previous', 'Next'],
      });
      carousel.getInfo().controlsContainer.classList.add('carousel-controls');
    }

    var reels = document.querySelectorAll('.reel');
    for (var i = 0; i < reels.length; i++) {
      var reel = tns({
        container: reels[i],
        items: 1.1,
        gutter: 10,
        mouseDrag: true,
        autoHeight: false,
        loop: false,
        rewind: true,
        nav: true,
        navPosition: 'bottom',
        controlsText: ['Previous', 'Next'],
        responsive: {
          900: {
            items: 1.5,
            gutter: 30,
          },
        },
      });
      reel.getInfo().controlsContainer.classList.add('reel-controls');
    }
  }
  document.addEventListener('pjax:success', initSliders);
  initSliders();
})(this);
