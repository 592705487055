// Implements EventListenerInterface

var checkboxEventHandler = {
  handleEvent: function (event) {
    // Case 1: Event is a KeyboardEvent of type keydown
    if (event.type === 'keydown') {
      // Case 1.1: Event target is a input label
      if (event.target.tagName === 'LABEL') {
        // Case 1.1.1: Keypress is spacebar or return key
        if (
          event.key === ' ' ||
          event.key === 'Spacebar' ||
          event.key === 'Enter'
        ) {
          event.preventDefault();

          // Trigger click on connected checkbox input
          document.querySelector('#' + event.target.htmlFor).click();
        }
      }
    }

    // Case 2: Event is of change type
    else if (event.type === 'change') {
      // Case 2.1: Event target is an input tag and its type is checkbox
      if (
        event.target.tagName === 'INPUT' &&
        event.target.getAttribute('type') === 'checkbox'
      ) {
        // Case 2.1.1: Checkbox has associated selector to enable/disable tabbing for when checkbox is checked/unchecked
        if (event.target.hasAttribute('data-tab-hide-selector')) {
          var elements = document.querySelectorAll(
            event.target.dataset.tabHideSelector
          );

          // If checkbox is activated, make all elements covered under aforementioned selector tabbable
          if (event.target.checked) {
            Array.from(elements).forEach(function (element) {
              element.tabIndex = 0;
            });
          }

          // If checkbox is de-activated, make all elements covered under aforementioned selector untabbable
          else {
            Array.from(elements).forEach(function (element) {
              element.tabIndex = -1;
            });
          }
        }
      }
    }
  },
};

document.addEventListener('keydown', checkboxEventHandler);
document.addEventListener('change', checkboxEventHandler);
