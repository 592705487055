// Track Meta pixel events on Formie form submission
// https://verbb.io/craft-plugins/formie/docs/developers/javascript-api

(function (window) {
  // Meta Pixel Tracking
  function addEventsOnFormSubmit() {
    if (typeof fbq !== 'undefined') {
      // Get an already-initialised form by DOM node
      var $formElements = document.querySelectorAll(
        'form[data-meta-pixel-events]'
      );

      // Add tracking events
      if ($formElements.length) {
        $formElements.forEach(function ($form) {
          $form.addEventListener('onAfterFormieSubmit', trackFormEvents);
        });
      }
    }
  }

  function trackFormEvents() {
    var pixelEvents = this.dataset.metaPixelEvents;
    var pixelEventArr = pixelEvents.split(',');

    for (var i = 0; i < pixelEventArr.length; i++) {
      fbq('track', pixelEventArr[i]);
    }
  }

  document.addEventListener('pjax:success', addEventsOnFormSubmit);
  addEventsOnFormSubmit();
})(this);
