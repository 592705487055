// Initialize asset file download on successful Formie form submission
// https://verbb.io/craft-plugins/formie/docs/developers/javascript-api

(function (window) {
  function initFormieDownload() {
    // Get an already-initialised form by DOM node
    var $forms = document.querySelectorAll('[data-form="downloadForm"]');
    $forms.forEach(function ($form) {
      if ($form != null) {
        // Initialize asset download after successful form submission
        $form.addEventListener('onAfterFormieSubmit', onAfterFormieSubmit);
      }
    });
  }

  // Perform actions on Formie form submit
  function onAfterFormieSubmit(e) {
    e.preventDefault();

    $form = e.target;
    var $formContainer = $form.closest('[data-form-container]');
    if ($formContainer) {
      // Hide download file information on parent div
      $formContainer
        .querySelectorAll('[data-form-hide-on-submit]')
        .forEach(removeElement);

      // Show success message
      var $messageTemplate = $formContainer.querySelector(
        'template[data-form-message]'
      );
      if ($messageTemplate) {
        var $clone = document.importNode($messageTemplate.content, true);
        $form.parentElement.insertBefore($clone, $form.nextSibling);
      }
    }

    // Check if download url is available
    if (e.detail.assetUrl !== undefined && e.detail.assetUrl != '') {
      var $link = document.createElement('a');
      $link.href = e.detail.assetUrl;
      $link.target = '_blank';

      // Open download file in a new tab
      $link.dispatchEvent(new MouseEvent('click'));
    }
  }

  // Method to remove elements
  function removeElement($el) {
    $el.remove();
  }

  document.addEventListener('pjax:success', initFormieDownload);
  initFormieDownload();
})(this);
