/*
  lightboxes.js
  
  Activate basic lightboxes
  https://github.com/electerious/basicLightbox
*/

(function (w) {
  'use strict';

  var SELECTORS = {
    CLOSE_SELECTOR: '[data-lightbox-close]',
    TRIGGER_SELECTOR: '[data-lightbox-trigger]',
    TRIGGER_ATTRIBUTE: 'lightboxTrigger',
  };

  function initLightboxes() {
    var triggers = document.querySelectorAll(SELECTORS.TRIGGER_SELECTOR);
    var lightboxes = Array.from(triggers).reduce(function (
      accumulator,
      currentValue
    ) {
      var key = currentValue.dataset[SELECTORS.TRIGGER_ATTRIBUTE];
      if (typeof accumulator[key] === 'undefined') {
        accumulator[key] = [];
      }
      accumulator[key].push(currentValue);
      return accumulator;
    },
    {});

    Object.keys(lightboxes).forEach(function (sourceSelector) {
      var source = document.querySelector(sourceSelector);
      if (source) {
        var lightbox = basicLightbox.create(source, {
          onShow: function (instance) {
            bodyScrollLock.disableBodyScroll(instance.element());
          },
          onClose: function (instance) {
            bodyScrollLock.enableBodyScroll(instance.element());
          },
        });
        lightboxes[sourceSelector].forEach(function (trigger) {
          trigger.addEventListener('click', lightbox.show);
        });
        lightbox
          .element()
          .querySelectorAll(SELECTORS.CLOSE_SELECTOR)
          .forEach(function (closeTrigger) {
            closeTrigger.addEventListener('click', lightbox.close);
          });
      }
    });
  }

  document.addEventListener('pjax:success', initLightboxes);
  initLightboxes();
})(this);
