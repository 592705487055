/*
  toggleheight.js
  
  Set the max-height for checkbox based toggle elements.
  Based on https://css-tricks.com/using-css-transitions-auto-dimensions/
*/

(function (w) {
  'use strict';

  var SELECTORS = {
    SWITCH: 'input.toggle-input[type=checkbox]',
    ELEMENT: '.toggle-input ~ .toggle-checked',
  };
  var HEIGHT_VAR = '--maxToggleHeight';
  var HEIGHT_FACTOR = 1.5;

  function setToggleHeight(element) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // find all (collapsed) child toggles and add their max-heights as well
    sectionHeight =
      sectionHeight +
      Array.from(element.querySelectorAll(SELECTORS.SWITCH))
        .filter(function (childToggleSwitch) {
          return !childToggleSwitch.checked;
        })
        .map(function (childToggleSwitch) {
          return childToggleSwitch.parentNode.querySelector(SELECTORS.ELEMENT);
        })
        .reduce(function (childToggleHeights, childToggle) {
          return childToggleHeights + childToggle.scrollHeight;
        }, 0);

    // set toggle target to a little more than the height of its inner content
    element.style.setProperty(HEIGHT_VAR, sectionHeight * HEIGHT_FACTOR + 'px');
  }

  document.addEventListener('change', function (e) {
    var toggleSwitch = e.target;

    // only proceed if we have a matching checkbox
    // and it is checked (so we have to expand)
    if (
      !(
        toggleSwitch instanceof HTMLInputElement &&
        toggleSwitch.matches(SELECTORS.SWITCH) &&
        toggleSwitch.checked
      )
    ) {
      return;
    }

    Array.from(toggleSwitch.parentNode.querySelectorAll(SELECTORS.ELEMENT)).map(
      setToggleHeight
    );
  });
})(this);
