function checkboxAsRadio(selector) {
  return function (event) {
    var el = event.target;
    
    // only proceed if we have a matching checkbox
    if (!(el instanceof HTMLInputElement && el.matches(selector))) {
      return;
    }
    
    // close all open checkboxes with the same name attribute
    if (el.checked) {
      Array
        .from(document.querySelectorAll('input[name=' + el.name + ']'))
        .filter(function (checkbox) {
          return checkbox.checked && checkbox !== el;
        })
        .map(function (checkbox) {
          checkbox.checked = false;
        });
    }
  };
}

document.addEventListener('change', checkboxAsRadio('.checkbox-as-radio'));
