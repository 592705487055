// Uncheck any `input[type=checkbox]` (`[data-checkboxscope-input]`)
// outside a defined scope area (`[data-checkboxscope-container]`)
//
// Eg:
//
// <div data-checkboxscope-container>
//   <input data-checkboxscope-input type=checkbox>
// </div>
// <div>
//   <!-- a click here will uncheck the checkbox above -->
// </div>

(function (w) {
  
  function closeScopedCheckboxes(checkbox_selector, scope_selector) {
    return function (event) {
      var el = event.target;
      document
        .querySelectorAll(scope_selector)
        .forEach(function(toggleScope) { 
          if (!toggleScope.contains(el)) {
            Array
              .from(toggleScope.querySelectorAll(checkbox_selector))
              .filter(function (checkbox) {
                return checkbox.checked && !(el instanceof HTMLLabelElement && checkbox == el.control);
              })
              .map(function (checkbox) {
                checkbox.click();
              });
          }
        });
    };
  }
  
  document.addEventListener('click', closeScopedCheckboxes('[data-checkboxscope-input]', '[data-checkboxscope-container]'));
  document.addEventListener('touchend', closeScopedCheckboxes('[data-checkboxscope-input]', '[data-checkboxscope-container]'));
  
}(this));